<template>
  <base-section id="news">
    <base-section-heading title="News" />

    <v-container>
      <v-row class="d-flex justify-center">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/PraxisMonicaBachmann"
          data-tabs="timeline"
          data-width="1920"
          data-height=""
          data-small-header="true"
          data-adapt-container-width="false"
          data-hide-cover="true"
          data-show-facepile="false"
        >
          <blockquote
            cite="https://www.facebook.com/PraxisMonicaBachmann"
            class="fb-xfbml-parse-ignore"
          >
            <a
              href="https://www.facebook.com/PraxisMonicaBachmann"
            >Praxis Monica Bachmann</a>
          </blockquote>
        </div>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    components: {
    },

    data: () => ({
      articles: [
        {
          icon: 'mdi-image',
          date: 'Jan 12, 2020',
          category: 'Design',
          comments: 5,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
        {
          icon: 'mdi-play',
          date: 'Oct 19, 2019',
          category: 'Strategy',
          comments: 8,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
        {
          icon: 'mdi-text',
          date: 'Jul 24, 2019',
          category: 'Business',
          comments: 13,
          title: 'Lorem ipsum dolor, sit amet consectetur',
          text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tenetur, nulla voluptas sed[...]',
        },
      ],
    }),
  }
</script>
